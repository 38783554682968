<template>
  <div>
    <div
      class="bg-gray-9 rounded-1 anim-pulse mb-3"
      style="width: 100%; height: 34px;"
    />
    <div
      class="bg-gray-9 rounded-1 anim-pulse mb-3"
      style="width: 40%; height: 34px;"
    />
    <div
      class="bg-gray-9 rounded-1 anim-pulse mb-4"
      style="width: 65px; height: 28px;"
    />
  </div>
</template>
